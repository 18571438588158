import { Component, Watch, Mixins } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import GtrChangelog from '@/modules/common/components/changelog/changelog.vue'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import SettingsPageMixin from '@/modules/common/components/mixins/settings-page.mixin'

@Component({
  name: 'GtrRegistrationLeadsSettingsView',
  computed: {
    ...mapState('setting', ['lr_settings', 'lr_settingsFields', 'providerFields'])
  },
  components: {
    'gtr-changelog': GtrChangelog
  }
})
export default class GtrRegistrationLeadsSettingsView extends Mixins(SettingsPageMixin, GtrSuper) {
  private readonly settings_type = 'lr_settings'

  data (): Record<string, any> {
    return {
      formVersion: 'default',
      onlyShowDefault: ['require_reg_type_before_registering', 'list_view_fields']
    }
  }

  async mounted () {
    this.$store.commit('setting/SET_SETTINGS_LR', [])
    this.$store.commit('setting/SET_PROVIDER_FIELDS', [])
    this.$store.commit('setting/SET_SETTINGS_FIELDS_LR', {})
    await this.fetchSettings('setting/getSettingsLR', 'setting/SET_SETTINGS_LR')
    await this.fetchSettingsFields('setting/getSettingsFieldsLR')
    await this.fetchChangelog(this.settings_type)
    await this.fetchCurrentlyDeployedLiveUUID(this.settings_type)
    this.$store.dispatch('common/hideLoader')
  }

  @Watch('settingsLoaded', { deep: true })
  onSettingsLoadedChange (newVal: any) {
    if (newVal) {
      const settingsObject = {}
      const settingsFields = this.$store.state.setting.lr_settingsFields
      const languages = this.$store.state.languages.languages
      for (const category in settingsFields) {
        settingsObject[category] = {}
        for (const setting in settingsFields[category]) {
          settingsObject[category][setting] = {}
          settingsObject[category][setting].name = setting
          /**
           * Add type to the setting
           */
          settingsObject[category][setting].name = setting
          settingsObject[category][setting].show_field = true
          settingsObject[category][setting].type = settingsFields[category][setting].type
          settingsObject[category][setting].description = settingsFields[category][setting].description
          settingsObject[category][setting].display_name = settingsFields[category][
            setting
          ].display_name
          settingsObject[category][setting].provider = settingsFields[category][setting].provider
          settingsObject[category][setting].default = settingsFields[category][setting].default
          settingsObject[category][setting].value = {}
          settingsObject[category][setting].temp = null
          if (settingsFields[category][setting].show_if) {
            settingsObject[category][setting].show_if = settingsFields[category][setting].show_if
          }

          if (settingsFields[category][setting].type === 'select') {
            settingsObject[category][setting].selectItems = settingsFields[category][setting].options
          }
          for (let i = 0; i < this.registrationTypes.length; i++) {
            /**
             * Only add the _default registration type to settings that are not registration-type specific
             */
            if (this.$data.onlyShowDefault.includes(setting) && this.registrationTypes[i].value !== '_default') {
              continue
            }
            const regTypeUUID = this.registrationTypes[i].value // function scope for the below statement
            const settingType = settingsFields[category][setting].type
            if (
              settingType === 'localized' ||
              settingType === 'localized_html' ||
              settingType === 'field' ||
              settingType === 'fields' ||
              settingType === 'fieldmapping'
            ) {
              settingsObject[category][setting].value[this.registrationTypes[i].value] = {}

              if (settingType === 'localized' || settingType === 'localized_html') {
                for (let i = 0; i < languages.length; i++) {
                  if (!settingsObject[category][setting].value[regTypeUUID][languages[i].name]) {
                    settingsObject[category][setting].value[regTypeUUID][languages[i].name] = ''
                  }
                }
              }

              if (settingsFields[category][setting].type === 'field') {
                settingsObject[category][setting].value[this.registrationTypes[i].value].label = {}
                settingsObject[category][setting].value[this.registrationTypes[i].value].field = ''
                settingsObject[category][setting].error = ''

                for (let i = 0; i < languages.length; i++) {
                  if (!settingsObject[category][setting].value[regTypeUUID].label[languages[i].name]) {
                    settingsObject[category][setting].value[regTypeUUID].label[languages[i].name] = ''
                  }
                }
              }

              if (settingsFields[category][setting].type === 'fields') {
                settingsObject[category][setting].error = ''
              }
              if (
                settingsFields[category][setting].type === 'fields' ||
                settingsFields[category][setting].type === 'fieldmapping'
              ) {
                settingsObject[category][setting].value[this.registrationTypes[i].value] =
                  typeof settingsFields[category][setting].default !== 'undefined'
                    ? settingsFields[category][setting].default
                    : []
              }

              if (typeof settingsFields[category][setting].default === 'object') {
                settingsObject[category][setting].value[this.registrationTypes[i].value] = settingsFields[category][setting].default
              }
            } else {
              if (settingsFields[category][setting].type === 'yn' && this.registrationTypes[i].value !== '_default') {
                continue
              }

              settingsObject[category][setting].value[this.registrationTypes[i].value] =
                typeof settingsFields[category][setting].default !== 'undefined'
                  ? settingsFields[category][setting].default
                  : ''
              if (settingType === 'yn') {
                settingsObject[category][setting].temp = settingsFields[category][setting].default
              }
            }
          }
        }
      }
      /**
       * Set value from this.settings
       */
      const settings = this.$store.state.setting.lr_settings
      if (settings.page_data) {
        for (const setting in settings.page_data) {
          for (const category in settingsObject) {
            if (settingsObject[category][setting]) {
              if (settingsObject[category][setting].type === 'datetime') {
                settingsObject[category][setting].value = {}
                for (const reg_type in this.registrationTypes) {
                  if (settings.page_data[setting][this.registrationTypes[reg_type].value]) {
                    settingsObject[category][setting].value[
                      this.registrationTypes[reg_type].value
                    ] = this.convertDatetimeToLocalTimezone(
                      settings.page_data[setting][this.registrationTypes[reg_type].value]
                    )
                  } else {
                    settingsObject[category][setting].value[this.registrationTypes[reg_type].value] = null
                  }
                }
              } else {
                // settingsObject[setting].value = settings.page_data[setting]
                for (const value in settings.page_data[setting]) {
                  if (settingsObject[category][setting].type === 'yn' && (typeof settings.page_data[setting][value] !== 'boolean')) {
                    settingsObject[category][setting].value[value] = !(Array.isArray(settings.page_data[setting][value]) && settings.page_data[setting][value].length === 0)
                  } else {
                    settingsObject[category][setting].value[value] = settings.page_data[setting][value]
                  }
                  if (settingsObject[category][setting].type === 'fields') {
                    settingsObject[category][setting].temp_field = null
                    settingsObject[category][setting].temp_label = null
                  } else if (settingsObject[category][setting].type === 'fieldmapping') {
                    settingsObject[category][setting].temp_field = null
                    settingsObject[category][setting].temp_external_field = null
                    settingsObject[category][setting].temp_value_type = null
                  } else if (settingsObject[category][setting].type === 'yn') {
                    settingsObject[category][setting].temp = settings.page_data[setting]._default
                  }
                }
              }
            }
          }
        }
      }
      this.settingsObject = settingsObject
      /**
       * Run show if checked now that the values are loaded in the settingsObject
       */
      this.showOrHideSettingsFields()
    }
  }

  @Watch('reportableEventFields')
  onReportableEventFieldsChange (newVal) {
    for (const fieldIndex in newVal.participants) {
      const field = newVal.participants[fieldIndex]
      this.fieldsPrint.push({ text: field.label, value: field.field })
    }
    for (const fieldIndex in newVal.option_groups) {
      const field = newVal.option_groups[fieldIndex]
      this.fieldsPrint.push({ text: 'Option Group: ' + field.label, value: field.field })
    }
  }

  @Watch('settingsVersion')
  async onSettingsVersionChange (settings_uuid: string): Promise<void> {
    if (settings_uuid !== 'default') {
      const response = await this.$store.dispatch('setting/getSettingsByUuid', {
        event_uuid: this.event_uuid,
        settings_uuid
      })
      if (response) {
        const settings = response.data.page_data
        for (const setting in settings) {
          if (this.settingsObject['GTR Leads'][setting]) {
            this.settingsObject['GTR Leads'][setting].value = settings[setting]
          }
        }
        this.$data._currentlyDeployedDevUUID = settings_uuid
      }
      Container.get(Notification).success('Settings version successfully changed.')
    }
  }

  get settingsLoaded () {
    const settingsFields = this.$store.state.setting.lr_settingsFields
    const languages = this.$store.state.languages.languages
    const settings = this.$store.state.setting.lr_settings
    this.$data._currentlyDeployedDevUUID = settings.uuid
    return (
      Object.keys(settingsFields).length > 0 &&
      Object.keys(languages).length > 0 &&
      this.registrationTypes.length > 0 &&
      settings !== false
    )
  }

  async saveSettings () {
    try {
      this.submitting = true
      const settingsData = {
        event_uuid: this.event_uuid
      }
      const _settingsObject = {}
      for (const category in this.settingsObject) {
        Object.assign(_settingsObject, this.settingsObject[category])
      }
      for (const setting in _settingsObject) {
        if (_settingsObject[setting].type === 'datetime') {
          settingsData[setting] = {}
          for (const registrationType in _settingsObject[setting].value) {
            if (_settingsObject[setting].value[registrationType]) {
              settingsData[setting][registrationType] = this.convertDatetimeToUTC(
                _settingsObject[setting].value[registrationType]
              )
            } else {
              settingsData[setting][registrationType] = _settingsObject[setting].value[
                registrationType
              ]
            }
          }
        } else if (_settingsObject[setting].type === 'url') { // if the type is a url.
          const temp = _settingsObject[setting].value // store the value of the setting.
          // setup a loop.
          let j = 0
          let count = Object.keys(temp).length

          // while j is less then count.
          while (j < count) {
            if (Object.keys(temp)[j].includes('temp')) { // if it has the word temp, delete it.
              delete temp[Object.keys(temp)[j]]
              count-- // decrement count.
            }
            j++ // increment j.
          }
          delete temp.temp_file
          settingsData[setting] = temp
        } else {
          settingsData[setting] = _settingsObject[setting].value
        }
      }
      await this.$store.dispatch('setting/saveSettingsLR', {
        event_uuid: this.event_uuid,
        data: settingsData
      })
      Container.get(Notification).success('Settings successfully saved.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.submitting = false
    }
  }
}
